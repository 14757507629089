import { Chart } from 'chart.js';
import { memo, useContext, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';

interface IProps {
  labels: string[];
  data: Array<string | number>;
  id: string;
}

interface ITooltipData {
  chart: typeof Chart;
  label: string;
  parsed: unknown;
  raw: unknown;
  formattedValue: string;
  dataset: unknown;
  datasetIndex: number;
  dataIndex: number;
  element: Element;
}

const buildDatasets = (
  themeContext: DefaultTheme,
  labels: string[],
  data: Array<string | number>
) => {
  const mainDataSet = {
    data,
    backgroundColor: '#E5E9EC',
    barThickness: 27,
    borderWidth: 1,
    borderColor: 'rgba(0, 40, 73, 0.3)',
  };

  return {
    labels,
    datasets: [mainDataSet],
  };
};

const ColumnChartBase = ({ labels, data, id }: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const chartData = useMemo(
    () =>
      buildDatasets(
        themeContext,
        [
          ...labels.map((l) => {
            const tokens = l.split('-');

            return `${tokens[1]}/${tokens[0].slice(2)}`;
          }),
        ],
        data
      ),
    [data, labels, themeContext]
  );

  const options = {
    scales: {
      x: {
        ticks: {
          color: 'rgba(0, 48, 94, .3)',
          maxRotation: 90,
          minRotation: 90,
          labelOffset: -6,
          align: 'center',
          padding: 4,
          font: {
            family: 'Roboto',
            size: 14,
            weight: '900',
            lineHeight: 1.143,
          },
        },
        grid: {
          display: false,
          drawTicks: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          padding: 10,
          color: 'rgba(0, 48, 94, 1)',
          font: {
            family: 'Roboto',
            size: 14,
            weight: '700',
            letterSpacing: 0.44,
            lineHeight: 1.6,
          },
        },
        grid: {
          drawBorder: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: '#fff',
        titleColor: hexToRGB(themeContext.blue, 0.8),
        titleFont: {
          family: 'Roboto',
          size: 12,
          weight: '900',
          lineHeight: 1.67,
        },
        titleAlign: 'center',
        titleMarginBottom: 4,
        bodyColor: themeContext.blue,
        bodyFont: {
          family: 'Source Serif Pro',
          size: 16,
          weight: '600',
          lineHeight: 1.25,
        },
        bodyAlign: 'center',
        footerColor: hexToRGB(themeContext.blue, 0.5),
        footerFont: {
          family: 'Roboto',
          size: 10,
          weight: '900',
          lineHeight: 1.6,
        },
        footerAlign: 'center',
        footerMarginTop: 2,
        padding: {
          left: 20,
          right: 20,
          top: 8,
          bottom: 8,
        },
        cornerRadius: 3,
        borderColor: 'rgb(217, 217, 217)',
        borderWidth: 1,
        caretSize: 10,
        caretPadding: 8,
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => {
            return {
              pointStyle: 'triangle',
              rotation: 1,
            };
          },
          title: (context: [ITooltipData]) => {
            const { label } = context[0];
            if (!label) {
              return '';
            }

            return label.replace('/', ' - ');
          },
          label: (context: ITooltipData) => {
            const { formattedValue } = context;

            return `${formattedValue || ''} ${
              formattedValue === '1'
                ? t('chart.tooltip.day')
                : t('chart.tooltip.days')
            }`;
          },
          footer: () => {
            return t('chart.days-on-market.footer');
          },
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
  };

  return <Bar id={id} data={chartData} options={options} />;
};

const ColumnChart = memo(ColumnChartBase);

export { ColumnChart };
