import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Headline2,
  Headline4,
  Headline3,
  BoxText,
} from 'modules/common/components/ui/typography';
import Icon from 'modules/common/components/ui/icon';
import { ReactComponent as ActivitiesIcon } from 'assets/streamline-light/internet-networks-servers/data-transfer/data-transfer-three-back-forth-back.svg';
import { BaseLinkButton } from 'modules/common/components/ui/buttons/base-link-button';
import { SecondaryButton } from 'modules/common/components/ui/buttons';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';
import { ReactComponent as ArrowDown } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-down-1.svg';
import { GlobalLoader } from 'modules/common/components/ui/loaders/global-loader';
import {
  getDate,
  getTime,
} from 'modules/search-profile/components/appointments/utils';

import {
  Activity,
  AppointmentActivitiesQuery,
  DocumentsActivitiesQuery,
  DownloadedExposeActivitiesQuery,
  ExposeActivitiesQuery,
  HandOverActivitiesQuery,
  NotaryActivitiesQuery,
  PriceOfferActivitiesQuery,
  PropertyCode,
  RequestedExposeActivitiesQuery,
  // SentExposeActivitiesQuery,
  useAppointmentActivitiesQuery,
  useDocumentsActivitiesQuery,
  useDownloadedExposeActivitiesQuery,
  useExposeActivitiesQuery,
  useHandOverActivitiesQuery,
  useLatestActivitiesQuery,
  useNotaryActivitiesQuery,
  usePriceOfferActivitiesQuery,
  useRequestedExposeActivitiesQuery,
  useSentExposeActivitiesQuery,
  usePhoneCallsActivitiesQuery,
  useMarketingActivitiesQuery,
  PhoneCallsActivitiesQuery,
  MarketingActivitiesQuery,
} from 'generated';

import { device } from '../../../../style/theme';

import { TeamContactCard } from '../appointment-card/team-contact-card';
import { Accordion } from '../accordion/accordion';
import { extractPropertyTypeLabelAndIcon } from 'modules/property/utils/property-type-normalizer';

const Container = styled.div`
  position: relative;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
    padding: 16px;
  }
`;

const FirstColumn = styled.div`
  flex: 1.72;
  max-width: 688px;
  margin-right: 16px;

  padding: 32px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.white};

  @media ${device.tablet} {
    padding: 16px;
    margin-bottom: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  max-width: 400px;
  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

const PageTitle = styled(Headline2)`
  margin-top: 16px;
  margin-bottom: 6px;
  text-align: center;
`;

const Title = styled(Headline3)``;

const Subtitle = styled(Headline4)`
  text-align: center;
`;

const LastActivities = styled.div`
  padding: 12px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  margin: 16px 0;
  display: grid;
  gap: 12px;
`;

const LastActivitiesHead = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LastActivitiesFooter = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const ActivityCardContainer = styled.div`
  padding: 12px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  border-left: solid 5px ${(props) => props.theme.ctaBlue};
`;

const ActivityCardTitle = styled(Headline3)`
  font-size: 16px;
`;

const List = styled.div`
  display: grid;
  gap: 12px;
  position: relative;
`;

const Loader = styled.div`
  position: relative;
  min-height: 80px;
`;

const LoadMoreButton = styled(BaseLinkButton)`
  font-size: 14px;
  padding: 0;
  color: ${(props) => props.theme.ctaBlue};
`;

export const LoadMoreButtonMobile = styled(SecondaryButton)`
  justify-content: center;
`;

export const CollapseButton = styled(SecondaryButton)`
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccordionWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const CounterBadge = styled.div`
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.lightBlue};
  background-color: ${(props) => props.theme.greyBackground};
  margin: 0 0 0 12px;
  font-family: Roboto, 'sans-serif';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};

  @media ${device.tablet} {
    font-size: 8px;
    margin-left: unset;
    padding: 2px 6px;
    width: max-content;
  }
`;

const AccordionTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const AccordionTitleLine = styled.div`
  flex-grow: 2;
  opacity: 0.5;
  background-color: ${(props) => props.theme.blue};
  height: 2px;
`;

const hooks = {
  documents: useDocumentsActivitiesQuery,
  expose: useExposeActivitiesQuery,
  appointments: useAppointmentActivitiesQuery,
  requestedExpose: useRequestedExposeActivitiesQuery,
  // sentExpose: useSentExposeActivitiesQuery,
  downloadedExpose: useDownloadedExposeActivitiesQuery,
  priceOffer: usePriceOfferActivitiesQuery,
  notary: useNotaryActivitiesQuery,
  handOver: useHandOverActivitiesQuery,
  phoneCalls: usePhoneCallsActivitiesQuery,
  marketing: useMarketingActivitiesQuery,
};

interface IActivityGroup {
  id: keyof typeof hooks;
  title: string;
}

const activityGroups: IActivityGroup[] = [
  {
    id: 'documents',
    title: 'marketing-activity-group.documents',
  },
  {
    id: 'expose',
    title: 'marketing-activity-group.expose',
  },
  {
    id: 'requestedExpose',
    title: 'marketing-activity-group.requested-expose',
  },
  // {
  //   id: 'sentExpose',
  //   title: 'marketing-activity-group.sent-expose',
  // },
  {
    id: 'downloadedExpose',
    title: 'marketing-activity-group.downloaded-expose',
  },
  {
    id: 'appointments',
    title: 'marketing-activity-group.visits',
  },
  {
    id: 'priceOffer',
    title: 'marketing-activity-group.price-offer',
  },
  {
    id: 'notary',
    title: 'marketing-activity-group.notary',
  },
  {
    id: 'handOver',
    title: 'marketing-activity-group.handing-over',
  },
  {
    id: 'phoneCalls',
    title: 'marketing-activities-group.phone-calls',
  },
  {
    id: 'marketing',
    title: 'marketing-activities-group.marketing',
  },
];

interface ActivityCardProps {
  activity: Activity;
}

interface ActivityGroupProps {
  group: IActivityGroup;
  propertyId: string;
}

/* -------------------------------------------------------------------------- */
/*                                Activity Card                               */
/* -------------------------------------------------------------------------- */

const ActivityCard = ({ activity }: ActivityCardProps) => {
  const { t } = useTranslation();

  return (
    <ActivityCardContainer>
      <ActivityCardTitle
        content={t(activity.title, {
          date: getDate(activity.dateTime),
          time: getTime(activity.dateTime?.replace('Z', '')),
        })}
      />

      <BoxText
        content={`${t(`marketing-activity-card.date`, {
          date: getDate(activity.createdAt, true),
        })}`}
      />
    </ActivityCardContainer>
  );
};

const ActivityGroupList = ({ group, propertyId }: ActivityGroupProps) => {
  const { t } = useTranslation();

  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const limit = 10;

  const [activities, setActivities] = useState<Activity[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [preloader, setPreloader] = useState<boolean>(false);

  const loadMore = () => {
    setOffset(offset + limit);
  };

  const { data, isLoading, isSuccess } = hooks[group.id]({
    offset,
    limit,
    propertyId,
  });

  useEffect(() => {
    let activitiesData: Activity[] = [];

    switch (group.id) {
      case 'documents':
        activitiesData = (data as DocumentsActivitiesQuery)
          ?.documentsActivities as Activity[];
        break;
      case 'expose':
        activitiesData = (data as ExposeActivitiesQuery)
          ?.exposeActivities as Activity[];
        break;
      case 'appointments':
        activitiesData = (data as AppointmentActivitiesQuery)
          ?.appointmentActivities as Activity[];
        break;
      case 'requestedExpose':
        activitiesData = (data as RequestedExposeActivitiesQuery)
          ?.requestedExposeActivities as Activity[];
        break;
      // case 'sentExpose':
      //   activitiesData = (
      //     (data as SentExposeActivitiesQuery)
      //       ?.sentExposeActivities as ActivityGroup[]
      //   )?.map((g) => g.activities);
      //   break;
      case 'downloadedExpose':
        activitiesData = (data as DownloadedExposeActivitiesQuery)
          ?.downloadedExposeActivities as Activity[];
        break;
      case 'priceOffer':
        activitiesData = (data as PriceOfferActivitiesQuery)
          ?.priceOfferActivities as Activity[];
        break;
      case 'notary':
        activitiesData = (data as NotaryActivitiesQuery)
          ?.notaryActivities as Activity[];
        break;
      case 'handOver':
        activitiesData = (data as HandOverActivitiesQuery)
          ?.handOverActivities as Activity[];
        break;
      case 'phoneCalls':
        activitiesData = (data as PhoneCallsActivitiesQuery)
          ?.phoneCallsActivities as Activity[];
        break;
      case 'marketing':
        activitiesData = (data as MarketingActivitiesQuery)
          ?.marketingActivities as Activity[];
        break;
      default:
        activitiesData = [];
        break;
    }

    if (activitiesData) {
      setActivities([...activities, ...activitiesData]);
      setHasMore(activitiesData.length >= limit);
    }
  }, [data]);

  useEffect(() => {
    setPreloader(isLoading);
  }, [isLoading]);

  return (
    <List onClick={(e) => e.stopPropagation()}>
      {activities.map((activity, i) => (
        <ActivityCard activity={activity} key={i} />
      ))}

      {isSuccess && activities.length === 0 && (
        <ActivityCardTitle content={`${t('seller-activities.empty-feed')}`} />
      )}

      {preloader && (
        <Loader>
          <GlobalLoader isAbsolute />
        </Loader>
      )}

      {/* {hasMore && ( */}
      {/*  <> */}
      {/*    {!isMobileSize && ( */}
      {/*      <LoadMoreButton onClick={() => loadMore()}> */}
      {/*        {t('button.load-more')} */}
      {/*      </LoadMoreButton> */}
      {/*    )} */}
      {/*    {isMobileSize && ( */}
      {/*      <LoadMoreButtonMobile */}
      {/*        onClick={() => loadMore()} */}
      {/*        fluid */}
      {/*        label={t('button.load-more')} */}
      {/*        color={themeContext.blue} */}
      {/*        borderColor={themeContext.blue} */}
      {/*      /> */}
      {/*    )} */}
      {/*  </> */}
      {/* )} */}
    </List>
  );
};

const LatestActivityGroup = ({ propertyId }: { propertyId: string }) => {
  const { t } = useTranslation();
  const limit = 5;

  const [activities, setActivities] = useState<Activity[]>([]);
  const [preloader, setPreloader] = useState<boolean>(false);

  const { data, isLoading, isSuccess } = useLatestActivitiesQuery({
    limit,
    propertyId,
  });

  useEffect(() => {
    if (data) {
      setActivities(data?.latestActivities as Activity[]);
    }
  }, [data]);

  useEffect(() => {
    setPreloader(isLoading);
  }, [isLoading]);

  return (
    <List>
      {activities.map((activity, i) => (
        <ActivityCard activity={activity} key={i} />
      ))}

      {isSuccess && activities.length === 0 && (
        <ActivityCardTitle content={`${t('seller-activities.empty-feed')}`} />
      )}

      {preloader && (
        <Loader>
          <GlobalLoader isAbsolute />
        </Loader>
      )}
    </List>
  );
};

interface IProps {
  propertyId: string;
  code?: PropertyCode | null;
  address?: string;
}

const ActivitiesBase = ({ propertyId, code, address }: IProps) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isAccordionsOpen = useRef<boolean>(false);
  const isMobileSize = useIsMobileSize();
  const { label } = extractPropertyTypeLabelAndIcon(code);

  const accordionsInitialState = useMemo(() => {
    const activitiesAccordions =
      activityGroups?.map((item, index) => ({
        id: item.id,
        isOpen: false,
        isScrolledToBottom: false,
      })) ?? [];
    return [
      ...activitiesAccordions,
      { id: 'activities', isOpen: true, isScrolledToBottom: false },
    ];
  }, [activityGroups]);

  const [accordionsState, setAccordionsState] = useState<
    { id: string; isOpen: boolean; isScrolledToBottom: boolean }[] | undefined
  >(accordionsInitialState);

  const onCollapseSingleAccordion = useCallback((id: string) => {
    setAccordionsState((prevState) => {
      return prevState?.map((accordion) => {
        return {
          ...accordion,
          isOpen: accordion.id === id ? !accordion.isOpen : accordion.isOpen,
        };
      });
    });
  }, []);

  const onCollapseAllAccordions = useCallback(() => {
    isAccordionsOpen.current = !isAccordionsOpen.current;
    setAccordionsState((prevState) =>
      prevState?.map((accordion) => ({
        ...accordion,
        isOpen: isAccordionsOpen.current,
      }))
    );
  }, []);

  return (
    <>
      <Container>
        <FirstColumn>
          <PageTitle content={t('seller-activities.title')} />
          <Subtitle content={`${t(label)}, ${address}`} />

          <LastActivities>
            <LastActivitiesHead>
              <Icon
                icon={ActivitiesIcon}
                width={16}
                height={16}
                color={themeContext.blue}
                style={{
                  rotate: '-90deg',
                }}
              />

              <Title content={t('seller-activities.last-activities.title')} />
            </LastActivitiesHead>

            <LatestActivityGroup propertyId={propertyId} />
          </LastActivities>

          <AccordionTitle>
            <Subtitle content={t('seller-activities.phases.title')} />
            <AccordionTitleLine />
            <CollapseButton
              label={''}
              onClick={onCollapseAllAccordions}
              icon={ArrowDown}
              color={themeContext.grey2}
              iconStyle={{
                width: '16px',
                height: '8px',
                margin: 0,
                transition: `transform 500ms ease`,
                transform: isAccordionsOpen.current
                  ? 'rotate(-180deg)'
                  : 'none',
                strokeWidth: '3px',
              }}
              borderColor={themeContext.borderColor}
            />
          </AccordionTitle>

          <AccordionWrapper>
            {activityGroups.map(({ id, title }, index) => {
              const isAccordionOpen = accordionsState?.find(
                (accordion) => accordion.id === id
              )?.isOpen;

              return (
                <Accordion
                  key={`group-${id}`}
                  id={`${id}`}
                  isOpen={isAccordionOpen}
                  onChange={onCollapseSingleAccordion}
                  accordionSection={{
                    _id: index.toString(),
                    title: t(title),
                    expandedContent: (
                      <ActivityGroupList
                        group={activityGroups[index]}
                        propertyId={propertyId}
                      />
                    ),
                  }}
                  isBadgeAtBottom={isMobileSize}
                />
              );
            })}
          </AccordionWrapper>
        </FirstColumn>
        <SecondColumn>
          <TeamContactCard />
        </SecondColumn>
      </Container>
    </>
  );
};

const Activities = memo(ActivitiesBase);

export { Activities };
